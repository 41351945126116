<!-- 口說測驗題型介紹 -->
<template>
  <div class="reciteIntro">
    <!-- 測驗介紹 -->
    <div class="reciteIntro-content">
      <img :src="imgFile" alt="口說測驗介紹">
    </div>
    <!-- 測驗音頻 -->
    <audio class="reciteIntro-audio" ref="singeBox"></audio>
    <!-- 測驗開始 -->
    <div class="reciteIntro-button">
      <div class="button" @click="quizStarts">
        口說測驗開始
        <img src="@/assets/image/icon/icon-next.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import imgFile7 from "@/assets/image/testIntro/recite7.jpg"
import imgFile8 from "@/assets/image/testIntro/recite8.jpg"
import audioFile7 from "@/assets/audio/recite/level7.mp3";
import audioFile8 from "@/assets/audio/recite/level8.mp3";
import ExamInformation from "../ExamInformation.vue"
import ShareButton from "../ShareButton.vue"
import ProblemReturns from "../ProblemReturns.vue"
export default {
  name: 'ReciteIntro',
  components: {
    ExamInformation,
    ShareButton,
    ProblemReturns
  },
  data() {
    return {
      // 音频实例
      audioBox: '',
    }
  },
  computed: {
    ...mapGetters(['problemPopupStatus', 'entryLevel']),
    // 音频文件
    audioFile() {
      let file = ''
      switch(this.entryLevel) {
        case '7':
          file = audioFile7
          break;
        case '8':
          file = audioFile8
          break;
      }
      return file
    },
    // 圖片文件
    imgFile() {
      let file = ''
      switch(this.entryLevel) {
        case '7':
          file = imgFile7
          break;
        case '8':
          file = imgFile8
          break;
      }
      return file
    }
  },
  watch: {
    // 監聽問題回報彈窗 同步暫停/開啟音頻
    problemPopupStatus(val) {
      if (val) {
        this.audioBox.pause()
      } else {
        this.audioBox.play()
      }
    }
  },
  mounted() {
    this.audioInit()
  },
  methods: {
    // 播放初始化
    init() {
      this.audioBox.play()
    },
    // 音頻初始化
    audioInit() {
      this.audioBox = this.$refs.singeBox
      this.audioBox.src = this.audioFile
      const _that = this
      // 当时长有变化时触发，由"NaN"变为实际时长也算
      this.audioBox.ondurationchange = function() {
        console.log('时长发生了变化')
      }
      // 当前数据可用是触发
      this.audioBox.oncanplay = function() {
        console.log('已经可以播放了')
      }
      // 播放位置发送改变时触发。
      this.audioBox.ontimeupdate = function() {
        console.log('播放位置发送了变动')
      }
      // 音频播放完毕
      this.audioBox.onended = function() {
        console.log('播放完毕，谢谢收听')
      }
      // 音频播放完毕
      this.audioBox.onerror = function() {
        console.log('加载出错！')
      }
    },
    // 分享熒幕
    shareChange(type) {
      this.$store.commit('shareChange', type)
    },
    // 測驗開始
    quizStarts() {
      this.$emit('quizStarts')
    },
    
    // 考試級別
    levelReturn() {
      let str = '第一級'
      switch(this.entryLevel) {
        case '1':
          str = '第一級'
          break;
        case '2':
          str = '第二級'
          break;
        case '3':
          str = '第三級'
          break;
        case '4':
          str = '第四級'
          break;
        case '5':
          str = '第五級'
          break;
        case '6':
          str = '第六級'
          break;
        case '7':
          str = '第七級'
          break;
        case '8':
          str = '第八級'
          break;
      }
      return str
    }
  }
}
</script>

<style lang="less" scoped>
.reciteIntro {
  margin: 12px auto;
  width: 1320px;
  .reciteIntro-content {
    width: 100%;
    height: 566px;
    background: #FFFFFF;
    border-radius: 8px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 8px;
    }
  }
  .reciteIntro-audio {
    display: none;
  }
  .reciteIntro-button {
    margin-top: 45px;
    display: flex;
    justify-content: flex-end;
    .button {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 282px;
      height: 60px;
      background: #50CE8E;
      border-radius: 8px;
      font-family: "Microsoft JhengHei","微軟正黑體";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 24px;
      letter-spacing: 0.01em;
      color: #FFFFFF;
      img {
        margin-left: 6px;
        width: 30px;
        height: 30px;
      }
    }
  }
}
</style>