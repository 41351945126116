<!-- 口說測驗錄准考證號碼-->
<template>
  <div class="TopicReciteTicket">
    <h1 class="TopicReciteTicket-h1">
      錄准考證號碼
    </h1>
    <div class="TopicReciteTicket-box">
      <div class="box-div box-left">
        <div class="div-title">
          <img src="@/assets/image/icon/icon-hear.png" alt="">
          請聽題
        </div>
        <div class="div-audio">
          <div class="div-audio-box">
            <div class="audio-left">
              <img v-if="playStatus" src="@/assets/image/icon/icon-plays.png" alt="">
              <img v-if="!playStatus" src="@/assets/image/icon/icon-plays-dis.png" alt="">
            </div>
            <div class="audio-right">
              <div class="audio-right-time">
                <span>{{playCurrentTime}}</span>
                <span>{{playDuration}}</span>
              </div>
              <div class="audio-right-progress">
                  <el-slider v-model="playSchedule" :show-tooltip="false" disabled></el-slider>
                <!-- <div class="progress-round" :style="{ left: playSchedule + '%' }"></div>
                <el-progress :percentage="playSchedule" :show-text="false"></el-progress> -->
                <audio ref="singeBox"></audio>
              </div>
            </div>
          </div>
          <div class="div-audio-title">
            {{ ticketStatus == 1 ? '請在15秒內完成並唸出下列自我介紹的句子。' : '請將下列自我介紹的句子再唸一遍。'}}<br />
            My test number is ____________________.
            <span>（准考證號碼）</span>
          </div>
        </div>
      </div>
      <div class="box-div box-right">
        <div class="div-title">
          <img src="@/assets/image/icon/icon-hear.png" alt="">
          請作答
        </div>
        <div class="div-audio">
          <div class="div-audio-box">
            <div class="audio-left">
              <img v-if="recordStatus" src="@/assets/image/icon/icon-stops.png" alt="">
              <img v-if="!recordStatus" src="@/assets/image/icon/icon-stops-dis.png" alt="">
            </div>
            <div class="audio-right">
              <div class="audio-right-time">
                <span>{{ recordCurrentTime }}</span>
                <span>{{ recordDuration }}</span>
              </div>
              <div class="audio-right-progress">
                  <el-slider v-model="recordSchedule" :show-tooltip="false" disabled></el-slider>
                <!-- <div class="progress-round" :style="{ left: recordSchedule + '%' }"></div>
                <el-progress :percentage="recordSchedule" :show-text="false"></el-progress> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { fileUpload } from "@/request/api"
import ticket1 from "@/assets/audio/recite/ticket1.mp3";
import ticket2 from "@/assets/audio/recite/ticket2.mp3";
// import lamejs from 'lamejstmp'
// import Recorder from 'js-audio-recorder'
import Recorder from 'recorder-core';
//引入mp3格式支持文件；如果需要多个格式支持，把这些格式的编码引擎js文件放到后面统统引入进来即可
import 'recorder-core/src/engine/mp3';
import 'recorder-core/src/engine/mp3-engine';
export default {
  name: 'TopicReciteTicket',
  props: ['ticketStatus'],
  data() {
    return {
      // 播放音频实例对象
      playAudio: '',
      // 播放音频状态
      playStatus: true,
      // 播放音频总时长
      playDuration: '00:00',
      // 播放音频当前播放时长
      playCurrentTime: '00:00',
      // 播放音频当前進度
      playSchedule: 0,


      // 录制音频-答案录制时长 15s
      recordTime: 15,
      // 录制音频实例对象
      recordAudio: '',
      // 录制音频状态
      recordStatus: false,
      // 录制音频总时长
      recordDuration: '00:00',
      // 录制音频当前播放时长
      recordCurrentTime: '00:00',
      // 录制音频当前進度
      recordSchedule: 0,

      // 錄音開始時間 用於計算倒計時
      recordStartTime: 0,

      // 播放狀態
      recorderPlaySuatus: false,
      recordingDuration: 0,
      totalRecordingDuration : 0,
    }
  },
  computed: {
    ...mapGetters(['problemPopupStatus', 'admissionTicket']),
  },
  watch: {
    // 監聽問題回報彈窗 同步暫停/開啟音頻
    problemPopupStatus(val) {
      if (val) {
        if (this.playStatus) {
          this.playAudio.pause()
        }
        if (this.recordStatus) {
          this.recordAudio.pause()
          this.recordingDuration = this.totalRecordingDuration
          this.recorderPlaySuatus = true
        }
      } else {
        if (this.playStatus) {
          this.playAudio.play()
        }
        if (this.recordStatus) {
          this.recordAudio.resume()
          this.recorderPlaySuatus = false
          this.recordStartTime = Date.now()
          this.recordCountTime()
        }
      }
    }
  },
  mounted() {
    this.audioInit()
  },
  methods: {
    // 播放音频
    init() {
      this.playAudio.play()
      this.playStatus = true
    },
    // 播放音频初始化
    audioInit() {
      // 初始化录音总时长
      const duration = this.formatTime(this.recordTime)
      this.recordDuration = `${duration.min}:${duration.sec}`
      this.recordCountDown = this.recordTime

      this.playAudio = this.$refs.singeBox
      if (this.ticketStatus == 1) { // 1第一遍准考证  2第二遍准考证
        this.playAudio.src = ticket1
      } else {
        this.playAudio.src = ticket2
      }
      const _that = this
      // 当时长有变化时触发，由"NaN"变为实际时长也算
      this.playAudio.ondurationchange = function() {
        _that.audioInitUpdateTime()
      }
      // 播放位置发送改变时触发。
      this.playAudio.ontimeupdate = function() {
        _that.audioInitUpdateTime()
      }
      // 音频播放完毕
      this.playAudio.onended = function() {
        _that.playStatus = false
        _that.recordStart()
      }
    },
    // 更新时间
    audioInitUpdateTime() {
      const total = this.formatTime(this.playAudio.duration)
      const current = this.formatTime(this.playAudio.currentTime)
      this.playDuration = `${total.min}:${total.sec}`
      this.playCurrentTime = `${current.min}:${current.sec}`
      this.playSchedule = Number(this.playAudio.currentTime / this.playAudio.duration * 100)

      // // 录音倒计时初始化
      // this.recordDuration = `${total.min}:${total.sec}`
      // this.recordCurrentTime = '00:00'
    },

    // 开始录音
    recordStart() {
      this.recordStatus = true
      const newRec = Recorder({
        type: 'mp3',
        bitRate: 16,
        sampleRate: 16000, //阿里采样率16000
        onProcess: function (buffers, powerLevel, duration, bufferSampleRate) {
          // console.log(buffers);
        },
      })
      newRec.open(() => {
        this.recordAudio = newRec;
        // 开始录音
        this.recordAudio.start()
        this.recordStartTime = Date.now()
        // 开启录音倒计时
        this.recordCountTime()
      }, () => {
        this.$message({
          message: '請允許該網頁使用麥克風',
          type: 'info'
        })
      })
    },
    // 录音倒计时
    async recordCountTime() {
      if (this.recorderPlaySuatus) {
        return false
      }
      let currentTime = Date.now()
      let countdown = ((currentTime - this.recordStartTime) / 1000) + this.recordingDuration

      this.totalRecordingDuration = countdown
      // 进度条处理
      let schedule = Number(countdown / this.recordTime * 100)
      this.recordSchedule = schedule >= 100 ? 100 : schedule
      
      // 倒计时
      const current = this.formatTime(Math.floor(countdown))
      this.recordCurrentTime = `${current.min}:${current.sec}`

      // 判断播放总时间和录制进度时间
      if (countdown - 0.2 >= this.recordTime) {
        // // 停止录音
        // this.recordAudio.stop()
        // 禁用按钮
        this.recordStatus = false
        
        
        this.recordAudio.stop(async (blob, duration) => {
          console.log('录音成功', blob, '时长:' + duration + 'ms');
          // 停止錄音
          this.recordAudio.close();
          this.recordAudio = null;
          let formData = new FormData()
          formData.append('file', blob)
          formData.append('file_name', `${this.admissionTicket}_0_准考證${this.ticketStatus}`)
          await fileUpload(formData).then(res => {
            // 時間置空
            this.recordStartTime = 0
            this.totalRecordingDuration = 0
            this.recordingDuration = 0
            this.$nextTick(() => {
              // 跳转下一题
              this.$emit('ticketChange', res.data.url)
            })
          })
        })


        // let file = this.convertToMp3(this.recordAudio.getWAV())
        // file = new File([file], `${this.admissionTicket}_0_准考證${this.ticketStatus}.mp3`)
        // // // 文件转换
        // // const wavBlob = this.recordAudio.getWAVBlob()
        // // const file = new window.File([wavBlob], '考生音頻.wav', {type: 'audio/wav'}) // 转成file类型
        // // console.log(file)

        // let formData = new FormData()
        // formData.append('file', file)
        // formData.append('file_name', `${this.admissionTicket}_0_准考證${this.ticketStatus}`)
        // await fileUpload(formData).then(res => {
        //   // 跳转下一题
        //   this.$emit('ticketChange', res.data.url)
        // })
      } else {
        setTimeout(this.recordCountTime, 100)
      }
    },
    
    convertToMp3(wavDataView) {
      // 获取wav头信息
      const wav = lamejs.WavHeader.readHeader(wavDataView); // 此处其实可以不用去读wav头信息，毕竟有对应的config配置
      const { channels, sampleRate } = wav;
      console.log('wav', wav)
      const mp3enc = new lamejs.Mp3Encoder(channels, sampleRate, 128);
      // 获取左右通道数据
      const result = this.recordAudio.getChannelData()
      const buffer = [];
    
      const leftData = result.left && new Int16Array(result.left.buffer, 0, result.left.byteLength / 2);
      const rightData = result.right && new Int16Array(result.right.buffer, 0, result.right.byteLength / 2);
      const remaining = leftData.length + (rightData ? rightData.length : 0);
    
      const maxSamples = 1152;
      for (let i = 0; i < remaining; i += maxSamples) {
        const left = leftData.subarray(i, i + maxSamples);
        let right = null;
        let mp3buf = null;
    
        if (channels === 2) {
          right = rightData.subarray(i, i + maxSamples);
          mp3buf = mp3enc.encodeBuffer(left, right);
        } else {
          mp3buf = mp3enc.encodeBuffer(left);
        }
    
        if (mp3buf.length > 0) {
          buffer.push(mp3buf);
        }
      }
    
      const enc = mp3enc.flush();
    
      if (enc.length > 0) {
        buffer.push(enc);
      }
      return new Blob(buffer, { type: 'audio/mp3' });
    },

    // 格式化毫秒，返回String型分秒对象
    formatTime(time) {
      // 有可能没获取到，为NaN
      if (!time) return { min: '00', sec: '00' }
      return {
        min: Math.floor(time / 60).toString().padStart(2, '0'),
        sec: Math.floor(time % 60).toString().padStart(2, '0')
      }
    },
  }
}
</script>

<style lang="less" scoped>
.TopicReciteTicket {
  width: 100%;
  height: 100%;
  .TopicReciteTicket-h1 {
    padding-bottom: 30px;
    font-family: "Microsoft JhengHei","微軟正黑體";
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 35px;
    color: #0C4FA2;
  }
  .TopicReciteTicket-box {
    width: 100%;
    height: calc(100% - 75px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    .box-div {
      width: calc(50% - 10px);
      height: 100%;
      background: #F5F7FD;
      border-radius: 8px;
      .div-title {
        padding: 0 20px;
        width: calc(100% - 40px);
        height: 65px;
        border-radius: 8px 8px 0 0;
        display: flex;
        align-items: center;
        font-family: "Microsoft JhengHei","微軟正黑體";
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 35px;
        color: #FFFFFF;
        img {
          margin-right: 10px;
          width: 30px;
          height: 30px;
        }
      }
      .div-audio {
        padding: 70px 20px 20px;
        .div-audio-box {
          padding: 30px 16px;
          width: calc(100% - 32px);
          background: #FFFFFF;
          border: 1px solid #3774E4;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          .audio-left {
            margin-right: 16px;
            width: 54px;
            height: 54px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .audio-right {
            width: calc(100% - 70px);
            .audio-right-time {
              margin-bottom: 10px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-family: "Microsoft JhengHei","微軟正黑體";
              font-style: normal;
              font-weight: 700;
              font-size: 24px;
              line-height: 32px;
              color: #4F4F4F;
            }
            .audio-right-progress {
              width: 100%;
              height: 12px;
              position: relative;
              .progress-round {
                position: absolute;
                top: -2px;
                width: 16px;
                height: 16px;
                z-index: 1;
                transform: translateX(-50%);
                border-radius: 50%;
                background: #4277FF;
                box-shadow: 0px 4px 4px rgba(8, 76, 155, 0.25), inset 0px 0px 12px rgba(147, 133, 235, 0.4);
              }
              /deep/ .el-progress-bar__outer {
                height: 12px !important;
                background-color: #D3D3D3;
              }
              /deep/ .el-progress-bar__inner {
                background-color: unset;
                background-image: linear-gradient(90deg, #266DD7 3.31%, #84B5FF 100%);
              }
              /deep/ .el-slider__runway {
                margin: 0;
                width: 100%;
                height: 12px;
                background-color: #D3D3D3;
                border-radius: 12px;
                .el-slider__bar {
                  width: 100%;
                  height: 100%;
                  border-radius: 12px;
                  background-image: linear-gradient(90deg, #266DD7 3.31%, #84B5FF 100%);
                }
              }
              /deep/ .el-slider__button-wrapper {
                margin-top: 11px;
                width: 16px;
                height: 16px;
                z-index: 2;
                // border-radius: 50%;
                // background: #4277FF;
                // box-shadow: 0px 4px 4px rgba(8, 76, 155, 0.25), inset 0px 0px 12px rgba(147, 133, 235, 0.4);
                .el-slider__button {
                  width: 16px;
                  height: 16px;
                  border: none;
                  background: #4277FF;
                  box-shadow: 0px 4px 4px rgba(8, 76, 155, 0.25), inset 0px 0px 12px rgba(147, 133, 235, 0.4);
                }
              }
            }
          }
        }
        .div-audio-title {
          margin-top: 20px;
          font-family: "Microsoft JhengHei","微軟正黑體";
          font-style: normal;
          font-weight: 700;
          font-size: 28px;
          line-height: 37px;
          color: #666666;
          position: relative;
          span {
            position: absolute;
            top: 75px;
            right: 125px;
            font-family: "Microsoft JhengHei","微軟正黑體";
            font-style: normal;
            font-weight: 400;
            font-size: 22px;
            line-height: 29px;
            text-align: center;
            color: #666666;
          }
        }
      }
    }
    .box-left {
      .div-title {
        background-image: url('../../../../assets/image/examination/hearing3.png');
      }
    }
    .box-right {
      .div-title {
        background-image: url('../../../../assets/image/examination/hearing4.png');
      }
    }
  }
}
</style>